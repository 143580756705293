import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/src/app/_components/ClientSideInitializer/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/_widgets/body-dynamic-article-with-related-articles/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/_widgets/body-dynamic-breadcrumb/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/_widgets/body-dynamic-list-by-all-categories-and-sections/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/_widgets/body-dynamic-list-of-links/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/_widgets/body-dynamic-page-title/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/_widgets/body-dynamic-paginated-link-list/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/_widgets/body-dynamic-show-notification-banners/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/_widgets/body-dynamic-show-promoted-articles/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/_widgets/body-dynamic-simple-search/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/_widgets/body-dynamic-ticket-form/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/_widgets/body-static-high-full-simple-banner-with-image/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/_widgets/body-static-list-by-session/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/_widgets/body-static-page-title/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/_widgets/body-static-selector-contact-by-persona/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/_widgets/footer-static-default-footer/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/_widgets/header-dynamic-default-header/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/_widgets/ui-static-multiple-simple-banner/index.tsx");
